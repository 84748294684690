import './sectionContact.css';
import SectionTitle from '../sectiontitle/sectionTitle';
import * as React from 'react';
import ContactForm from '../form/contactForm';

const SectionContact = () => {

    return (
        <div className='section contact-section'>
            <SectionTitle title='Formularz kontaktowy' />
            <ContactForm />
        </div>
    );
};

export default SectionContact;