import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import SectionServices from '../components/section/sectionServices';
import SectionAgencies from '../components/section/sectionAgency';
import SectionMap from '../components/section/sectionMap';
import Banner from '../components/banner/banner';
import { usePageData } from '../queries/queries';
import SectionAbout from '../components/section/sectionAbout';
import SectionContact from '../components/section/sectionContact';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { CookieConsent } from 'react-cookie-consent';
import data from '../../data/data';

const IndexPage = () => {
    const { bannersQuery, servicesQuery, agenciesQuery } = usePageData();

    return (
        <Layout>
            <Banner banners={bannersQuery} />
            <SectionAbout />
            <SectionServices services={servicesQuery} />
            <SectionAgencies agencies={agenciesQuery} />
            <SectionMap />
            <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA}>
                <SectionContact />
            </GoogleReCaptchaProvider>
            <CookieConsent
                location='bottom'
                buttonText='Ok'
                cookieName='page-cookie'
                style={{ background: '#304b6d', color: '#fff', border: "solid 1px #fff" }}
                buttonStyle={{ background: '#304b6d', color: '#fff', fontSize: '13px', border: 'solid 1px #fff' }}
                expires={150}

            >
                <div dangerouslySetInnerHTML={{ __html: data.cookie }}></div>
            </CookieConsent>
        </Layout>
    );
};


/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({location}) => <Seo title='' location={location}/>;

export default IndexPage;
