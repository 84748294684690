import * as React from 'react';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCallback, useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import data from '../../../data/data';

const headers = {
    'Accept': 'application/json',
    'Content-type': 'application/json; charset=utf-8;',
    'Authorization': `${process.env.GATSBY_API_KEY}`,
};

const ContactForm = () => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [ token, setToken ] = useState();
    const [ isSubmit, setSubmit ] = useState(false);

    const onVerify = useCallback((token) => {
        setToken(token);
    }, []);

    const onSubmit = data => {
        if (!token) {
            return;
        }

        setSubmit(true);
        //const url = 'https://formgate.you2.pl/send';
        const url = 'http://127.0.0.1:8080/send';
        fetch(url, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseData) => {
                if (!responseData.hasError) {
                    toast(responseData.message, {
                        type: 'success',
                    });
                    reset();
                } else {
                    toast(responseData.message, {
                        type: 'error',
                    });
                }
                setSubmit(false);
            })
            .catch((error) => {
                toast("Nie można wysłać wiadomości", {
                    type: 'error',
                });
                setSubmit(false);
                console.error(error);
            });
    };

    const errorMessages = {
        required: 'To pole jest wymagane.',
        invalidFormat: 'Nieprawidłwy format',
        min: 'Minimalna ilość znaków to: ',
        max: 'Maksymalna ilość znaków to: ',
    };

    return (
        <form className='contact-form' onSubmit={handleSubmit(onSubmit)}>
            <input type='hidden' name='_recipient' value='biuro@polisyprzemysl.pl' {...register('_recipient')} />
            <div className='contact-form__group'>
                <label className='contact-form__label' htmlFor='_sender_name'>
                    Imię i nazwisko
                </label>
                <input className='contact-form__input'
                       type='text'
                       name='_sender_name'
                       placeholder='Jan Kowalski'
                       {
                           ...register('_sender_name',
                               {
                                   required: {
                                       value: true,
                                       message: errorMessages.required,
                                   },
                                   pattern: {
                                       value: /^[a-żA-Ź ]+$/,
                                       message: errorMessages.invalidFormat + ', dozwolone tylko litery i spacja.',
                                   },
                               },
                           )}
                />
                {
                    errors._sender_name
                    && <span className='contact-form__error'>{errors._sender_name.message}</span>
                }
            </div>

            <div className='contact-form__group'>
                <label className='contact-form__label' htmlFor='_sender_email'>E-mail</label>
                <input className='contact-form__input'
                       type='email'
                       name='_sender_email'
                       placeholder='j.kowalski@example.com'
                       {...register('_sender_email',
                           {
                               required: {
                                   value: true,
                                   message: errorMessages.required,
                               },
                           },
                       )}
                />
                {errors._sender_email && <span className='contact-form__error'>{errors._sender_email.message}</span>}
            </div>

            <div className='contact-form__group'>
                <label className='contact-form__label' htmlFor='phone'>Telefon</label>
                <input className='contact-form__input'
                       type='tel'
                       name='phone'
                       placeholder='000000000'
                       {...register('phone', {
                           required: {
                               value: true,
                               message: errorMessages.required,
                           }, pattern: {
                               value: /[0-9]{3}[0-9]{3}[0-9]{3}/,
                               message: errorMessages.invalidFormat + ', np: 000000000',
                           },
                       })}
                />
                {errors.phone && <span className='contact-form__error'>{errors.phone.message}</span>}
            </div>

            <div className='contact-form__group'>
                <label className='contact-form__label' htmlFor='message'>Treść wiadomości</label>
                <textarea className='contact-form__input'
                          name='message'
                          id="message"
                          rows='6'
                          maxLength='200'
                          {...register('message', {
                              required: {
                                  value: true,
                                  message: errorMessages.required,
                              },
                              minLength: {
                                  value: 3,
                                  message: errorMessages.min + '3',
                              },
                              maxLength: {
                                  value: 200,
                                  message: errorMessages.max + '200',
                              },
                          })}
                />
                {errors.message && <span className='contact-form__error'>{errors.message.message}</span>}
            </div>
            <div className='contact-form__group'>
                <div className="contact-form__check">
                    <input id="_gdpr" name="_gdpr" type="checkbox"
                           {...register('_gdpr', {
                               required: {
                                   value: true,
                                   message: errorMessages.required,
                               }
                           })}
                        value="1"
                    />
                    <label className='contact-form__label' htmlFor='_gdpr' dangerouslySetInnerHTML={{__html: data.gdpr}}>
                    </label>
                </div>
                {errors._gdpr && <span className='contact-form__error'>{errors._gdpr.message}</span>}
            </div>

            <GoogleReCaptcha onVerify={onVerify} />
            <button className='contact-form__button' type='submit' disabled={isSubmit}>
                {isSubmit && <div className='dots'></div>}
                {!isSubmit && 'Wyślij'}
            </button>
            <ToastContainer />
        </form>
    );
};

export default ContactForm;