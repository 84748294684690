import { graphql, useStaticQuery } from 'gatsby';

export const usePageData = () => {
    return useStaticQuery(graphql`
        query {
            bannersQuery: allFile(filter: {relativeDirectory: {eq: "banner"}}) {
                edges {
                    node {
                        id
                        name
                        childImageSharp {
                            gatsbyImageData(
                                width: 1200
                                formats: WEBP
                                quality: 100
                                tracedSVGOptions: {background: "#ffffff"}
                                backgroundColor: "#ffffff"
                            )
                        }
                    }
                }
            }
            agenciesQuery: allFile(filter: {relativeDirectory: {eq: "agencies"}}) {
                edges {
                    node {
                        id
                        name
                        childImageSharp {
                            gatsbyImageData(
                                width: 120
                                formats: WEBP
                                quality: 100
                                tracedSVGOptions: {background: "#ffffff"}
                                layout: FIXED
                                backgroundColor: "#ffffff"
                            )
                        }
                    }
                }
            }
            servicesQuery: allFile(filter: {relativeDirectory: {eq: "services"}}) {
                edges {
                    node {
                        id
                        name
                        childImageSharp {
                            gatsbyImageData(
                                width: 200
                                formats: WEBP
                                quality: 100
                                tracedSVGOptions: {background: "#ffffff"}
                                backgroundColor: "#ffffff"
                                aspectRatio: 1.5
                            )
                        }
                    }
                }
            }
            employeesQuery: allFile(
                filter: {relativeDirectory: {eq: "employee"}}
                sort: {fields: name}
            ) {
                edges {
                    node {
                        id
                        name
                        childImageSharp {
                            gatsbyImageData(
                                width: 500
                                height: 600
                                formats: WEBP
                                quality: 100
                                tracedSVGOptions: {background: "#ffffff"}
                                backgroundColor: "#ffffff"
                                aspectRatio: 1.5
                            )
                        }
                    }
                }
            }
        }
    `);
};
