import './sectionAbout.css';
import { StaticImage } from 'gatsby-plugin-image';
import data from '../../../data/data';
import * as React from 'react';

const SectionAbout = ({employees}) => {

    return (
        <section className='section welcome-section'>
            <div className='welcome-section__picture-wrapper'>
                <StaticImage
                    className='welcome-section__picture'
                    src='../../images/seo.jpg'
                    loading='eager'
                    width={500}
                    quality={95}
                    formats={[ 'auto', 'webp' ]}
                    alt='seo'
                />
            </div>

            <div className='welcome-section__info'>
                <h1 className='welcome-section__title'>O nas</h1>
                <p className='welcome-section__desc'>
                    {data.desc}
                </p>
            </div>
        </section>
    );
};

export default SectionAbout;