import SectionTitle from '../sectiontitle/sectionTitle';
import * as React from 'react';
import './sectionServices.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const SectionServices = ({ services }) => {
    const { edges } = services;

    return (
        <section className='section service-section'>
            <SectionTitle title='Nasze usługi' />
            <div className='service-section__list'>
                {
                    edges?.map(edge => {
                        const name = edge.node.name.replaceAll('_', ' ');
                        const image = getImage(edge.node.childImageSharp);
                        return (
                            <div key={edge.node.id} className='service-section__item'>
                                <GatsbyImage
                                    className='service-section__image'
                                    image={image}
                                    alt={name}
                                />
                                <span className='service-section__title'>{name}</span>
                            </div>
                        );
                    })
                }
            </div>
        </section>
    );
};

export default SectionServices;