import './banner.css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper';

const Banner = ({ banners }) => {
    const { edges } = banners;

    return (
        <div className='banner'>
            <Swiper
                className='banner-swiper'
                modules={[ Pagination, Navigation, Autoplay ]}
                navigation
                slidesPerView={1}
                autoplay={
                    {
                        delay: 5000,
                        pauseOnMouseEnter: true,
                        disableOnInteraction: false,
                    }
                }
                pagination={{ clickable: true }}
            >
                {
                    edges?.map((edge, index) => {
                        const name = edge.node.name.replaceAll('_', ' ');
                        const image = getImage(edge.node.childImageSharp);
                        return (
                            <SwiperSlide
                                key={edge.node.id}
                                virtualIndex={index}
                                className='banner__item'
                            >
                                <GatsbyImage
                                    alt={name}
                                    image={image} />
                            </SwiperSlide>
                        );
                    })
                }
            </Swiper>
        </div>
    );
};

export default Banner;