import SectionTitle from "../sectiontitle/sectionTitle";
import * as React from "react";
import './sectionMap.css';

const SectionMap = () => (
    <section className="section location-section">
        <SectionTitle title="Gdzie nas znajdziesz"/>

        <div className="mapouter">
            <div className="gmap_canvas">
                <iframe title="googl maps" width="100%" height="500" id="gmap_canvas"
                        src="https://maps.google.com/maps?q=franciszkanska%2022%20przemy%C5%9Bl&t=&z=13&ie=UTF8&iwloc=&output=embed"
                        frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
            </div>
        </div>
    </section>
);

export default SectionMap;