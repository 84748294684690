import './sectionAgency.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import SectionTitle from '../sectiontitle/sectionTitle';
import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import data from '../../../data/data';

const SectionAgencies = ({ agencies }) => {
    const { edges } = agencies;
    return (
        <section className='section agency-section'>
            <SectionTitle title='Multiagencja ubezpieczeniowa' />
            <p className='agency__desc'>
                {data.agency}
            </p>
            <Swiper
                className='agency-swiper'
                modules={[ Pagination, Autoplay ]}
                spaceBetween={15}
                slidesPerView={2}
                breakpoints={{
                    768: {
                        slidesPerView: 3,
                    },
                    998: {
                        slidesPerView: 4,
                    },
                    1200: {
                        slidesPerView: 6,
                    },
                }}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                autoplay={{ delay: 2000, pauseOnMouseEnter: false }}
            >

                {
                    edges?.map((edge, index) => {
                        const name = edge.node.name.replaceAll('_', ' ');
                        const image = getImage(edge.node.childImageSharp);
                        return (
                            <SwiperSlide key={edge.node.id} virtualIndex={index} className='agency__item'>
                                <GatsbyImage
                                    alt={name}
                                    image={image} />
                            </SwiperSlide>
                        );
                    })
                }
            </Swiper>
        </section>
    );
};

export default SectionAgencies;