import './sectionTitle.css';
import React from 'react';
import PropTypes from 'prop-types';


const SectionTitle = ({ title, isH1 = false }) => (
    <div className='section-title'>
        {isH1 ? <h1>{title}</h1> : <h2>{title}</h2>}
        <hr />
    </div>
);

SectionTitle.propTypes = {
    title: PropTypes.string,
};

SectionTitle.defaultProps = {
    siteTitle: ``,
};

export default SectionTitle;